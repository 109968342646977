import { cx } from '@emotion/css'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/system'
import { node, oneOf, oneOfType, string } from 'prop-types'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import Slider from '../../particles/slider'
import globalTheme from '../../theme/default/global'
import { ControlsContainer, NavButton, Pagination, Root, TopContainer } from './carousel.styled'
import { unstable_useForkRef as useForkRef } from '@mui/utils'

const Carousel = forwardRef(function Carousel(
	{ children, controlsPosition, iconNext, iconPrevious, navigation, pagination, titleComponent, className, ...props },
	ref
) {
	const sliderRef = useRef()
	const handleSliderRef = useForkRef(ref, sliderRef)
	const prevRef = useRef()
	const nextRef = useRef()
	const paginationRef = useRef()

	const nextElSelector = '.swiper-button-next'
	const prevElSelector = '.swiper-button-prev'
	const paginationElSelector = '.swiper-pagination'

	const [renderedOnce, setRenderedOnce] = useState(false)

	const theme = useTheme()
	const smUp = useMediaQuery(theme.breakpoints.up('sm'))

	const updateControls = useCallback(() => {
		if (sliderRef.current && prevRef.current && nextRef.current) {
			const navigationParams = sliderRef.current.params.navigation
			const sliderNavigation = sliderRef.current.navigation

			sliderRef.current.update()

			//don't use refs because enable/disabled state won't work
			navigationParams.prevEl = navigation.prevEl || prevRef.current.firstChild //firstChild because current doesn't manage enabled/disabled status (known bug)
			navigationParams.nextEl = navigation.nextEl || nextRef.current.firstChild
			sliderNavigation.destroy() //otherwise navigation is messed up
			sliderNavigation.init()
			sliderNavigation.update()
		}

		if (sliderRef.current && paginationRef.current) {
			const currentPagination = sliderRef.current.pagination

			sliderRef.current.update()

			sliderRef.current.params.pagination.el = pagination.el || paginationRef.current.firstChild
			currentPagination.init()
			currentPagination.render()
			currentPagination.update()
		}
	}, [sliderRef, navigation?.nextEl, navigation?.prevEl, pagination?.el])

	useEffect(() => {
		if (renderedOnce && controlsPosition !== 'default') {
			updateControls(controlsPosition)
		} else {
			setRenderedOnce(true)
		}
	}, [controlsPosition, nextRef, prevRef, renderedOnce, updateControls, sliderRef, smUp])

	return (
		<Root controlsPosition={controlsPosition} navigation={navigation} className={cx('Carousel-root', className)}>
			{controlsPosition === 'default' && !!titleComponent && (
				<TopContainer controlsPosition={controlsPosition}>{titleComponent}</TopContainer>
			)}

			<Slider
				navigation={
					controlsPosition === 'top-right'
						? navigation && smUp
							? {
									nextEl: nextElSelector,
									prevEl: prevElSelector,
							  }
							: false
						: navigation
				}
				pagination={
					pagination && smUp
						? {
								clickable: true,
								el: paginationElSelector,
						  }
						: pagination
						? pagination
						: false
				}
				ref={handleSliderRef}
				controlsPosition={controlsPosition}
				{...props}
			>
				{children}
				{controlsPosition === 'top-right' && (
					<div slot="container-start">
						<TopContainer titleComponent={!!titleComponent}>
							{titleComponent}

							{smUp && (
								<ControlsContainer>
									{navigation && (
										<div ref={prevRef}>
											<NavButton
												action="prev"
												className={prevElSelector.replace('.', '')}
												component={iconPrevious}
												controlsPosition={controlsPosition}
												pagination={pagination}
											/>
										</div>
									)}
									{pagination && (
										<div ref={paginationRef}>
											<Pagination
												className={paginationElSelector.replace('.', '')}
												controlsPosition={controlsPosition}
											/>
										</div>
									)}
									{navigation && (
										<div ref={nextRef}>
											<NavButton
												action="next"
												className={nextElSelector.replace('.', '')}
												component={iconNext}
												controlsPosition={controlsPosition}
												pagination={pagination}
											/>
										</div>
									)}
								</ControlsContainer>
							)}
						</TopContainer>
					</div>
				)}
			</Slider>
		</Root>
	)
})

Carousel.defaultProps = {
	...Slider.defaultProps,
	controlsPosition: 'default',
	navigation: true,
	pagination: true,
	slidesPerView: 1,
	spaceBetween: 25,
	breakpoints: {
		[globalTheme.breakpoints.values.xsm]: {
			slidesPerView: 1.5,
			spaceBetween: 25,
		},
		[globalTheme.breakpoints.values.sm]: {
			slidesPerView: 1.8,
			spaceBetween: 25,
		},
		[globalTheme.breakpoints.values.md]: {
			slidesPerView: 3,
			spaceBetween: 55,
		},
		[globalTheme.breakpoints.values.lg]: {
			spaceBetween: 78,
			slidesPerView: 3,
		},
	},
}

Carousel.propTypes = {
	...Slider.propTypes,
	/**
	 * Position of controls (arrows and pagination)
	 */
	controlsPosition: oneOfType([oneOf(['default', 'top-right']), string]),
	/**
	 * Title component when controlsPosition is top-right
	 */
	titleComponent: node,
}

export default Carousel
